import Config from "../../config/config";
import { URLS } from "./urls";

export const MORTGAGE_SERVICE_CODE = 1;
export const MORTGAGE_FEATURES_CODE = {
  MORTGAGE_PERSONAL_AREA: 0,
};

const Utils = {
  KEYCODES: {
    TAB: 9,
    ENTER: 13,
    ESCAPE: 27,
    SPACE: 32,
    ARROW_DOWN: 40,
    ARROW_UP: 38,
  },

  buildImgLink: (imgPath, shared) => {
    let url = `${Config.s3ImagesLink}/marketing/${imgPath}`;

    if (shared) {
      url = `${Config.s3ImagesLink}/shared/${imgPath}`;
    }

    return url;
  },

  buildTenantPageImgLink: (imgPath) =>
    `${Config.s3ImagesLink}/marketing/tenantPage/${imgPath}`,
  buildPrImgLink: (imgPath) => `${Config.s3ImagesLink}/marketing/pr/${imgPath}`,
  buildTestimonialsImgLink: (imgPath) =>
    `${Config.s3ImagesLink}/marketing/testimonials/${imgPath}`,
  buildMortTestimonialsImgLink: (imgPath) =>
    `${Config.s3ImagesLink}/marketing/mort/TestimonialsSlider/${imgPath}`,
  buildMortImgLink: (imgPath) =>
    `${Config.s3ImagesLink}/marketing/mort/${imgPath}`,
  buildProductPageImageLink: (imgPath) =>
    `${Config.s3ImagesLink}/marketing/product-pages/${imgPath}`,
  buildNewsletterLink: (imgPath) =>
    `${Config.s3ImagesLink}/marketing/newsletter/${imgPath}`,
  buildDashboardImgLink: (imgPath, shared) => {
    let url = `${Config.s3ImagesLink}/dashboard/${imgPath}`;
    if (shared) {
      url = `${Config.s3ImagesLink}/shared/${imgPath}`;
    }

    return url;
  },
  buildLoginImgLink: (imgPath) =>
    `${Config.s3ImagesLink}/dashboard/login/${imgPath}`,
  buildMortPersonalAreaImgLink: (imgPath) =>
    `${Config.s3ImagesLink}/marketing/mort-personal-area/${imgPath}`,
  buildStandTogetherImgLink: (imgPath) =>
    `${Config.s3ImagesLink}/marketing/stand-together-banner/${imgPath}`,
  buildModalImgLink: (imgPath) =>
    `${Config.s3ImagesLink}/dashboard/modals/${imgPath}`,
  getLoginLink: () =>
    window.searchParams ? URLS.Login + window.searchParams : URLS.Login,

  focus: (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    }
  },

  openOnEnter: (evt, func) => {
    if (evt.keyCode === Utils.KEYCODES.ENTER) {
      func();
    }
  },

  numberWithComma: (number) =>
    number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",

  removeNumberCommas: (text) => {
    const textWithoutCommas = text.replace(/,/g, "");
    if (/^[\d]+$/.test(textWithoutCommas) || text === "") {
      return textWithoutCommas;
    } else {
      return undefined;
    }
  },

  gaEvent: (category, action, label) => {
    window.dataLayer.push({
      Category: category,
      Action: action,
      Label: label,
      event: "auto_event",
    });
  },

  gaVirtual: (text) =>
    window.dataLayer.push({
      virtual_page: "virtual/" + text,
      event: "virtual_page",
    }),
};

export default Utils;
