import React from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { URLS } from "../../../shared/urls";
import { LOGIN_TYPE } from "../../../../redux/consts";

export default function LoginButton({
  text,
  backToDataViewAfterLogin = false,
}) {
  return (
    <Link
      className="login-button-container"
      to={`${URLS.internalLogin}?type=${LOGIN_TYPE.NEWSLETTER}`}
      onClick={() => {
        if (backToDataViewAfterLogin) {
          sessionStorage.setItem("backToDataViewAfterLogin", true);
        }
      }}
    >
      {text}
    </Link>
  );
}
