import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "../autocomplete";
import Container from "../../../../../components/product-pages/container";

import {
  resetSearchValue,
  searchCity,
  searchStreet,
  selectCity,
  selectStreet,
  setStreets,
  setIsChatOpen,
  closeDataView,
  setMessages,
  setIsWaitingForResponse,
  setSearchedByRentCostOnce,
} from "../../../../../../redux/features/newsletterSlice";
import {
  STREET_AUTOCOMPLETE_PLACEHOLDER,
  CITY_AUTOCOMPLETE_PLACEHOLDER,
  ROOM_SELECT_TITLE,
  SEARCH_BUTTON_TEXT,
  ASSETS_PROPERTIES_MULTI_SELECT_TITLE,
  TITLE,
  SEARCH_BUTTON_TEXT_MOBILE,
} from "./texts";

import "./index.scss";
import RoomSelect from "./components/room-select";
import PropertyMultiSelect from "./components/asset-properties-multi-select";
import Accordion from "../../../filter/components/accordion";
import useIsMobile from "../../../../../hooks/useIsMobile";
import Slider from "../slider";
import { useLazyGetStreetsQuery } from "../../../../../../redux/services/address";
import { pushToGTM } from "../../../../../../helpers/gtm";
import apiClient from "../../../../../../api-client";
import { RENT_PRICELIST_CHAT_SERVICE } from "../../../../../../redux/consts";
import config from "../../../../../../config/config";
import { MONTH_NUMBER_TO_HEBREW_NAME } from "../../../../consts";

const amountsToSliderValue = {
  1: 30,
  2: 50,
  3: 70,
  4: 90,
  5: 120,
};

const GENERAL_ERROR_MESSAGE = {
  type: "text",
  position: "single",
  content: { text: "לא בטוח שהבנתי 😵‍💫 אפשר לנסח את השאלה מחדש?" },
  direction: "incoming",
};

const POSSIBLE_PROPERTIES = ["משופץ", "מרפסת", 'ממ"ד', "חניה"];

export default function FormContainer({ isDataViewVisible, setIsSearchOpen }) {
  const dispatch = useDispatch();

  const isMobile = useIsMobile(1000);
  const [getStreets] = useLazyGetStreetsQuery();

  const cities = useSelector((state) => state.newsletter.cities);
  const streets = useSelector((state) => state.newsletter.streets);

  const citySearch = useSelector((state) => state.newsletter.citySearch);
  const streetSearch = useSelector((state) => state.newsletter.streetSearch);

  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

  const selectedCity = useSelector((state) => state.newsletter.selectedCity);

  const isWaitingForResponse = useSelector(
    (state) => state.newsletter.chat.isWaitingForResponse
  );

  const dataViewSearchProperties = useSelector(
    (state) => state.newsletter.dataView?.selectedProperties
  );

  const selectedRoomAmount = useSelector(
    (state) => state.newsletter.selectedRoomAmount
  );

  const selectedStreet = useSelector(
    (state) => state.newsletter.selectedStreet
  );

  const handleCitySearch = (e) => {
    dispatch(searchCity(e.target.value));
  };

  const handleCitySelect = (city) => {
    dispatch(selectCity(city?.id));
  };

  const handleStreetSearch = async (e) => {
    dispatch(searchStreet(e.target.value));
    const response = await getStreets({
      prefix: e.target.value,
      city: selectedCity.name,
    }).unwrap();
    dispatch(
      setStreets(
        Object.values(response).map((street) => ({
          name: street,
          city_code: selectedCity.id,
        }))
      )
    );
  };

  const handleStreetSelect = (street) => {
    dispatch(selectStreet(street?.name));
  };

  const handleSearchReset = (input) => {
    if (input === "streetSearch") {
      dispatch(selectStreet(null));
    } else if (input === "citySearch") {
      dispatch(searchStreet(""));
      dispatch(selectStreet(null));
      dispatch(setStreets([]));
    }
    dispatch(resetSearchValue(input));
  };

  const [value, setValue] = useState(115);

  const handleChange = (value) => {
    if (!isLoggedIn) return;
    setValue(value);
  };

  useEffect(() => {
    setValue(amountsToSliderValue[selectedRoomAmount]);
  }, [selectedRoomAmount]);

  const appendMsg = (msg) => {
    dispatch(setMessages(msg));
  };

  async function handleSend(text) {
    dispatch(setIsWaitingForResponse(true));
    if (text.trim()) {
      appendMsg({
        type: "text",
        position: "single",
        content: { text },
        direction: "outgoing",
      });

      pushToGTM("צ׳אט - הודעה נשלחת", {
        content: text,
      });

      try {
        const chatBaseURL = `${
          config.serverlessAddr
            ? config.serverlessAddr
            : config.RENT_PRICELIST_CHAT_LOCAL
        }${RENT_PRICELIST_CHAT_SERVICE}`;

        const completeURL = `${chatBaseURL}/complete?searchTerm=${text}`;
        const lastMessageURL = `${chatBaseURL}/last-message`;

        dispatch(setSearchedByRentCostOnce(true));
        await apiClient({
          url: completeURL,
          method: "GET",
        });

        await new Promise((resolve) => {
          const lastMessagePollingInterval = setInterval(async () => {
            const res = await apiClient({
              url: lastMessageURL,
              method: "GET",
            });

            const answer = res.data?.data?.answer;
            if (!answer) return;

            appendMsg({
              type: "text",
              position: "single",
              content: { text: answer },
              direction: "incoming",
            });

            pushToGTM("צ׳אט - הודעה מתקבלת", {
              content: answer,
            });

            resolve(clearInterval(lastMessagePollingInterval));
          }, 5000);
        });
      } catch (err) {
        appendMsg(GENERAL_ERROR_MESSAGE);
      } finally {
        dispatch(setIsWaitingForResponse(false));
      }
    }
  }

  const openChatAndSendMessage = async () => {
    pushToGTM("מנגנון חיפוש - הפרטים מולאו ונשלחה הודעה בצ׳אט");

    const currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    if (currentDate.getMonth() === 0) {
      year -= 1;
    }

    if (currentDate.getMonth() === 0) {
      month = 11;
    } else {
      month -= 1;
    }

    const textToSend = `נכון לחודש ${
      MONTH_NUMBER_TO_HEBREW_NAME[month]
    } ${year}, בכמה אפשר להשכיר דירת ${selectedRoomAmount} חדרים, ב${
      selectedCity.name
    }, ${selectedStreet ? `ברחוב ${selectedStreet}` : ""} עם ${value} מ׳׳ר, ${
      dataViewSearchProperties.includes(POSSIBLE_PROPERTIES[0])
        ? "עם שיפוץ"
        : "ללא שיפוץ"
    }, ${
      dataViewSearchProperties.includes(POSSIBLE_PROPERTIES[1])
        ? "עם מרפסת"
        : "ללא מרפסת"
    }, ${
      dataViewSearchProperties.includes(POSSIBLE_PROPERTIES[2])
        ? 'עם ממ"ד'
        : 'ללא ממ"ד'
    }, ${
      dataViewSearchProperties.includes(POSSIBLE_PROPERTIES[3])
        ? "עם חניה"
        : "ללא חניה"
    }?`;
    dispatch(closeDataView());
    setIsSearchOpen(false);
    dispatch(setIsChatOpen(true));
    await handleSend(textToSend);
  };

  return (
    <Container
      className={`by-props-form-container-newsletter-search ${
        isLoggedIn ? "full-opacity" : ""
      }`}
    >
      <Accordion
        className="form-content-accordion"
        isOpen={isMobile || !isDataViewVisible}
      >
        {!isMobile && <h2>{TITLE}</h2>}
        <Autocomplete
          dataTestId="calculator-city-autocomplete"
          placeholder={CITY_AUTOCOMPLETE_PLACEHOLDER}
          list={cities}
          selected={selectedCity}
          searchValue={citySearch}
          handleSearch={handleCitySearch}
          handleSelect={handleCitySelect}
          resetSearchValue={() => handleSearchReset("citySearch")}
          openOnType
          />
        <Autocomplete
          dataTestId="calculator-street-autocomplete"
          placeholder={STREET_AUTOCOMPLETE_PLACEHOLDER}
          list={streets}
          selected={selectedStreet}
          searchValue={streetSearch}
          handleSearch={handleStreetSearch}
          handleSelect={handleStreetSelect}
          resetSearchValue={() => handleSearchReset("streetSearch")}
          openOnType
          allowEmptySearch={isLoggedIn}
        />
        <RoomSelect title={ROOM_SELECT_TITLE} dataTestId="calculator-rooms" />
        <Slider value={value} handleChange={handleChange} dataTestId="square-meter-slider" />
        <PropertyMultiSelect title={ASSETS_PROPERTIES_MULTI_SELECT_TITLE} />
      </Accordion>
      {isLoggedIn && (
        <button
          disabled={!selectedCity || !selectedStreet || isWaitingForResponse}
          onClick={openChatAndSendMessage}
          className={`search-button ${isDataViewVisible ? "no-margin" : ""}`}
          data-testid="by-street-search-button"
        >
          {isMobile ? SEARCH_BUTTON_TEXT_MOBILE : SEARCH_BUTTON_TEXT}
        </button>
      )}
    </Container>
  );
}
