const WeCheckDefs = {
    dashboardAddr: 'https://dashboard.wecheck.co.il',
    mortDashboardAddr: 'https://loansdashboard.wecheck.co.il',
    mortServerAddr: 'https://loanswebappapi.wecheck.co.il',
    siteAddr: 'https://www.wecheck.co.il',
    lpAddr: 'https://lp.wecheck.co.il',
    serverAddr : 'https://api.wecheck.co.il',
    serverlessAddr : 'https://gw.wecheck.co.il',
    googleMapsApiKey: 'AIzaSyC0mo1bm9T7v5kXpr-XMUspXsTjs-xsZ2o',
    serverVersion : '1.0.0.',
    s3ImagesLink : 'https://s3.eu-west-1.amazonaws.com/wecheck-images-prod-eu-west-1/origin/master/static_content',
    clientVersion : '1.0.0.7f71cd72f66e65ae41ea3a7f2c3a0e95e24668a4',
HOMEPAGE_URL:'https://www.wecheck.co.il'
};

export default WeCheckDefs;
