import { createApi } from "@reduxjs/toolkit/query/react";
import { LOGIN_TYPE, MORTGAGE_PERSONAL_AREA_SERVICE } from "../consts";
import config from "../../config/config";
import { axiosBaseQuery } from "../../api-client";

export const mortgagePersonalAreaApi = createApi({
  reducerPath: "mortgagePersonalAreaApi",
  baseQuery: axiosBaseQuery({
    baseUrl: config.serverlessAddr
      ? config.serverlessAddr
      : config.MORTGAGE_PERSONAL_AREA_LOCAL,
    redirectPrefix: `type=${LOGIN_TYPE.MORT_PERSONAL_AREA}`,
    newLogin: true,
  }),
  endpoints: (builder) => ({
    getMortUserStatus: builder.query({
      query: () => {
        return {
          url: `${MORTGAGE_PERSONAL_AREA_SERVICE}/user-details`,
          method: "GET",
        };
      },
    }),
    updateMortUser: builder.mutation({
      query: () => {
        return {
          url: `${MORTGAGE_PERSONAL_AREA_SERVICE}/update-user`,
          method: "POST",
        };
      },
    }),
    confirmExistingUser: builder.query({
      query: (phone) => {
        return {
          url: `${MORTGAGE_PERSONAL_AREA_SERVICE}/confirm-existing-user`,
          method: "GET",
          params: {
            phoneNumber: phone,
          },
        };
      },
    }),
    getQueStatus: builder.query({
      query: () => {
        return {
          url: `${MORTGAGE_PERSONAL_AREA_SERVICE}/que-status`,
          method: "GET",
        };
      },
    }),
    getRequestsStatus: builder.query({
      query: () => {
        return {
          url: `${MORTGAGE_PERSONAL_AREA_SERVICE}/requests-status`,
          method: "GET",
        };
      },
    }),
    getLoans: builder.query({
      query: (activeRequestsIds) => {
        return {
          url: `${MORTGAGE_PERSONAL_AREA_SERVICE}/loans`,
          method: "GET",
          params: {
            activeRequestsIds,
          },
        };
      },
    }),
  }),
});

export const {
  useLazyGetMortUserStatusQuery,
  useUpdateMortUserMutation,
  useLazyConfirmExistingUserQuery,
  useLazyGetQueStatusQuery,
  useLazyGetRequestsStatusQuery,
  useLazyGetLoansQuery,
} = mortgagePersonalAreaApi;
