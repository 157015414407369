import React, { useRef, useState } from "react";
import "./index.scss";
import { CTA_OPEN_TITLE, CTA_TEXT, DATA_BY_AREA_TITLE } from "./texts";
import { HeaderButtons } from "../../../components/header";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../../shared/urls";
import { LOGIN_BUTTON_TEXT } from "../search/components/login-hide/texts";
import useHandleCLickOutside from "../../../hooks/useHandleClickOutside";
import { LOGIN_TYPE } from "../../../../redux/consts";
import {
  setDataViewActiveTab,
  setIsChatOpen,
} from "../../../../redux/features/newsletterSlice";
import { useDispatch } from "react-redux";
import { DATAVIEW_TABS } from "../../consts";
import useIsMobile from "../../../hooks/useIsMobile";

export default function DoubleCheckCTA({ setIsSearchOpen }) {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const isMobile = useIsMobile(1000);

  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const isChatOpen = useSelector((state) => state.newsletter.chat.isOpen);

  const navigate = useNavigate();

  const ctaRef = useRef(null);

  const isPopupOpen = useSelector(
    (state) => state.promotional.currentPopupIndex
  );

  useHandleCLickOutside({
    reference: ctaRef,
    callback: () => setIsOpen(false),
    disabled: isPopupOpen || (isChatOpen && isMobile),
  });

  const handleClick = () => {
    if (!isLoggedIn)
      return navigate(`${URLS.internalLogin}?type=${LOGIN_TYPE.NEWSLETTER}`);
    setIsOpen((open) => !open);
  };

  const handleOpenSearchByArea = () => {
    dispatch(setIsChatOpen(false));
    dispatch(setDataViewActiveTab(DATAVIEW_TABS.RENT_COST));
    setIsSearchOpen(true);
  };

  return (
    <div ref={ctaRef} className="newsletter-cta-container">
      <div
        onClick={handleClick}
        className={`double-check-cta ${isOpen ? "is-open" : ""}`}
      >
        {isLoggedIn ? CTA_TEXT : LOGIN_BUTTON_TEXT}
      </div>
      {isLoggedIn && isMobile && (
        <div
          onClick={handleOpenSearchByArea}
          className={`double-check-cta white-background`}
        >
          {DATA_BY_AREA_TITLE}
        </div>
      )}
      <div className={`buttons-container ${isOpen ? "is-open" : ""}`}>
        <h4>{CTA_OPEN_TITLE}</h4>
        <div className="buttons-wrapper">
          <HeaderButtons />
        </div>
      </div>
    </div>
  );
}
