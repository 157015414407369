import { createApi } from "@reduxjs/toolkit/query/react";
import { LOGIN_TYPE, RENT_PRICELIST_MAP_SERVICE } from "../consts";
import config from "../../config/config";
import { axiosBaseQuery } from "../../api-client";

export const newsletterProtectedApi = createApi({
  reducerPath: "newsletterProtectedApi",
  baseQuery: axiosBaseQuery({
    baseUrl: config.serverlessAddr
      ? config.serverlessAddr
      : config.RENT_PRICELIST_MAP_LOCAL,
    redirectPrefix: `type=${LOGIN_TYPE.NEWSLETTER}`  
  }),
  endpoints: (builder) => ({
    getAssets: builder.query({
      query: ({ cityCode, startMonth, endMonth, year }) => {
        return {
          url: `${RENT_PRICELIST_MAP_SERVICE}/assets`,
          method: "GET",
          params: { cityCode, startMonth, endMonth, year },
        };
      },
    }),
    updateUserDetails: builder.mutation({
      query: ({ isApprovedAds }) => {
        return {
          url: `${RENT_PRICELIST_MAP_SERVICE}/update-user-details`,
          method: "POST",
          body: { isApprovedAds },
        };
      },
    }),
    getAvgRentForThreeMonthsProtected: builder.query({
      query: ({
        currentMonth,
        year,
        cityId,
        neighborhoodId,
        areaId,
        numberOfRooms,
      }) => {
        return {
          url: `${RENT_PRICELIST_MAP_SERVICE}/calc/avg-rent-three-months-protected`,
          method: "GET",
          params: {
            currentMonth,
            year,
            cityId,
            neighborhoodId,
            areaId,
            numberOfRooms,
          },
        };
      },
    }),
    getUserStatus: builder.query({
      query: () => {
        return {
          url: `${RENT_PRICELIST_MAP_SERVICE}/user-details`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetAssetsQuery,
  useUpdateUserDetailsMutation,
  useGetAvgRentForThreeMonthsProtectedQuery,
  useLazyGetUserStatusQuery,
  useGetUserStatusQuery
} = newsletterProtectedApi;
