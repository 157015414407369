import axios from "axios";
import config from "../config/config";
import { AUTH_SERVICE } from "../redux/consts";
import { URLS } from "../web/shared/urls";
import { LOGIN_METHODS } from "../redux/consts";
import {
  MORTGAGE_FEATURES_CODE,
  MORTGAGE_SERVICE_CODE,
} from "../web/shared/utils";

const apiClient = axios.create({
  withCredentials: true,
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      axios.isAxiosError(error) &&
      error.response &&
      error.response.status === 401 &&
      !error.config._retry
    ) {
      if (error.config.url.includes("refresh-token")) {
        return Promise.reject(error);
      }

      error.config._retry = true;

      try {
        await apiClient.post(
          config.serverlessAddr
            ? `${config.serverlessAddr}${AUTH_SERVICE}/refresh-token`
            : `${config.AUTH_LOCAL}${AUTH_SERVICE}/refresh-token`
        );
        return apiClient.request(error.config);
      } catch (refreshError) {
        console.error("Unable to refresh token", refreshError);
        window.location = URLS.internalLogin;
      }
    }

    return Promise.reject(error);
  }
);

export const axiosBaseQuery =
  ({ baseUrl, redirectPrefix = null, newLogin = false }) =>
  async ({ url, method, params, body }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        params,
        data: body,
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      });
      return { data: { ...result.data, status: result.status } };
    } catch (axiosError) {
      let error = axiosError.response?.data || { error: axiosError.message };

      if (
        axiosError.response &&
        axiosError.response.status === 401 &&
        !axiosError.config.url.includes("refresh-token") &&
        !axiosError.config.url.includes("auth/user-details")
      ) {
        try {
          await axios.post(
            config.serverlessAddr
              ? `${config.serverlessAddr}${AUTH_SERVICE}/refresh-token`
              : `${config.AUTH_LOCAL}${AUTH_SERVICE}/refresh-token`,
            {},
            { withCredentials: true }
          );

          const originalRequestConfig = axiosError.config;
          const retryResult = await axios(originalRequestConfig);
          return { data: retryResult.data };
        } catch (refreshError) {
          error = refreshError.response?.data || {
            error: refreshError.message,
          };

          if (newLogin) {
            window.location = `${
              config.envName === "local"
                ? config.authSiteAddr
                : config.HOMEPAGE_URL
            }${config.envName === "local" ? "" : "/auth"}?type=${
              LOGIN_METHODS.PHONE_AND_ID
            }&continue=${
              window.location.href
            }&service=${MORTGAGE_SERVICE_CODE}&feature=${
              MORTGAGE_FEATURES_CODE.MORTGAGE_PERSONAL_AREA
            }`;
          } else {
            const locationToRedirect = redirectPrefix
              ? `${URLS.internalLogin}?${redirectPrefix}`
              : URLS.internalLogin;

            const sameLocation =
              window.location.pathname === URLS.internalLogin;
            if (!sameLocation) window.location = locationToRedirect;
          }
        }
      }
      return {
        error: {
          status: axiosError.response?.status || 500,
          data: error,
        },
      };
    }
  };

export default apiClient;
