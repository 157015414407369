import { createSlice } from "@reduxjs/toolkit";
import { MAP_IMAGES } from "../../web/newsletter/components/data-view/maps";
import { MAP_OPTIONS } from "../../web/newsletter/components/map/options";
import { STEPS } from "../../web/newsletter/components/intro/consts";
import Utils from "../../web/shared/utils";
import { getFromMonth, getYears } from "../utils";
import { CROSS_COUNTRY_SELECT } from "../consts";
import { FIRST_MESSAGE } from "../../web/chat";
import { getCorrectDate } from "../../web/newsletter/utils";

export const initialRooms = [1, 2, 3, 4, 5];

const initialState = {
  active: false,
  cities: [],
  areas: [],
  neighborhoods: [],
  assets: [],
  streets: [],
  selectedCity: null,
  selectedArea: null,
  selectedStreet: null,
  selectedNeighborhood: null,
  closestCity: null,
  citySearch: "",
  areaSearch: "",
  streetSearch: "",
  selectedRoomAmount: 3,
  locationCards: null,
  locationCardsData: null,
  chat: {
    isOpen: false,
    messages: [FIRST_MESSAGE],
    isWaitingForResponse: false,
  },
  intro: {
    isVisible: true,
    step: STEPS.FIRST,
  },
  dataView: {
    isVisible: false,
    numberOfRooms: 3,
    title: "",
    data: null,
    activeTab: 1,
    selectedProperties: [],
    searchedByRentCostOnce: false,
  },
  oldPercentageValues: {
    monthToMonth: 0,
    supply: 0,
  },
  datesArray: [],
  currentDateIndex: 0,
  mapOptions: {
    center: MAP_OPTIONS.center,
    zoom: MAP_OPTIONS.zoom,
  },
  filter: {
    isOpen: false,
    rooms: initialRooms,
    date: {
      availableYears: getYears(new Date().getFullYear()),
      currentYearIndex: 0,
      from: new Date().getMonth(),
      to: null,
      isVisible: false,
    },
    infoDisplay: false,
  },
};

export const newsletterSlice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {
    setLocationCardsData: (state, action) => {
      state.locationCardsData = action.payload;
    },
    setMessages: (state, action) => {
      state.chat.messages = [...state.chat.messages, action.payload];
    },
    setIsChatOpen: (state, action) => {
      state.chat.isOpen = action.payload;
    },
    setIsWaitingForResponse: (state, action) => {
      state.chat.isWaitingForResponse = action.payload;
    },
    setActive: (state, action) => {
      state.active = action.payload;
    },
    setIsIntroVisible: (state, action) => {
      state.intro.isVisible = action.payload;
    },
    changeIntroStep: (state, action) => {
      state.intro.step === STEPS.SECOND
        ? (state.intro.isVisible = false)
        : (state.intro.step = state.intro.step += 1);
    },
    selectCity: (state, action) => {
      const city = state.cities.find((city) => city.id === action.payload);
      if (!city || !action.payload) {
        const closestCityFromSearch = state.cities.find(
          (city) => city.name === state.citySearch
        );
        if (!closestCityFromSearch) {
          state.selectedCity = null;
          state.citySearch = "";
          return state;
        }
        state.selectedCity = closestCityFromSearch;
        state.citySearch = closestCityFromSearch.name;
        return state;
      }

      state.selectedCity = city;
      state.citySearch = city.name;
      state.areaSearch = "";
      state.streetSearch = "";
      state.selectedArea = null;
      state.selectedNeighborhood = null;
      state.selectedStreet = null;
    },
    searchCity: (state, action) => {
      state.citySearch = action.payload;
      if (state.selectedCity) {
        state.selectedCity = null;
        state.selectedArea = null;
        state.selectedNeighborhood = null;
        state.selectedStreet = null;
      }
    },
    searchArea: (state, action) => {
      state.areaSearch = action.payload;
      state.selectedArea = null;
      state.selectedNeighborhood = null;
    },
    searchStreet: (state, action) => {
      state.streetSearch = action.payload;
    },
    setClosestCity: (state, action) => {
      state.closestCity = action.payload;
    },
    selectArea: (state, action) => {
      const currentSelectedArea = state.areas.find(
        (area) => area.id === action.payload
      );
      if (!currentSelectedArea) {
        const closestAreaFromSearch = state.areas.find(
          (area) => area.name === state.areaSearch
        );

        if (!closestAreaFromSearch) {
          state.selectedArea = null;
          state.areaSearch = "";
          return state;
        }
        state.selectedArea = closestAreaFromSearch;
        state.areaSearch = closestAreaFromSearch.name;
        return state;
      }

      state.selectedArea = currentSelectedArea;
      state.selectedNeighborhood = null;
      state.areaSearch = currentSelectedArea.name;
      if (state.selectedCity?.id !== currentSelectedArea.city_id) {
        state.selectedCity = state.cities.find(
          (city) => city.id === currentSelectedArea.city_id
        );
        state.citySearch = state.selectedCity.name;
      }
    },
    selectStreet: (state, action) => {
      const street = state.streets.find(
        (street) => street.name === action.payload
      );
      if (!street) {
        const closestStreetFromSearch = state.streets.find(
          (street) => street.name === state.streetSearch
        );
        if (!closestStreetFromSearch) {
          state.selectedStreet = null;
          state.streetSearch = "";
          return state;
        }
        state.selectedStreet = closestStreetFromSearch.name;
        state.streetSearch = closestStreetFromSearch.name;
        return state;
      }
      state.streetSearch = street.name;
      state.selectedStreet = street.name;
    },
    selectNeighborhood: (state, action) => {
      const currentSelectedNeighborhood = state.neighborhoods.find(
        (neighborhood) => neighborhood.id === action.payload
      );
      if (!currentSelectedNeighborhood) {
        const closestNeighborhoodFromSearch = state.neighborhoods.find(
          (neighborhood) => neighborhood.display_name === state.areaSearch
        );
        if (!closestNeighborhoodFromSearch) {
          state.selectedNeighborhood = null;
          state.areaSearch = "";
          return state;
        }
        state.selectedNeighborhood = closestNeighborhoodFromSearch;
        state.areaSearch = closestNeighborhoodFromSearch.display_name;
        return state;
      }
      state.selectedNeighborhood = currentSelectedNeighborhood;
      state.selectedArea = null;
      state.areaSearch = currentSelectedNeighborhood.display_name;
      if (state.selectedCity?.id !== currentSelectedNeighborhood.city_id) {
        state.selectedCity = state.cities.find(
          (city) => city.id === currentSelectedNeighborhood.city_id
        );
        state.citySearch = state.selectedCity.name;
      }
    },
    setSelectedProperties: (state, action) => {
      state.dataView.selectedProperties = action.payload;
    },
    selectRoomNumber: (state, action) => {
      state.selectedRoomAmount = action.payload;
    },
    closeDataView: (state) => {
      state.dataView.isVisible = false;
    },
    openDataView: (state) => {
      state.dataView.isVisible = true;
    },
    setDatesArray: (state, action) => {
      state.datesArray = action.payload.reverse();
      state.currentDateIndex = action.payload.length - 1;
    },
    setCurrentDateIndex: (state, action) => {
      state.currentDateIndex = action.payload;
    },
    setMapOptions: (state, action) => {
      state.mapOptions = action.payload;
    },
    setLocationCards: (state, action) => {
      state.locationCards = action.payload;
    },
    setDataViewActiveTab: (state, action) => {
      state.dataView.activeTab = action.payload;
    },
    setSearchedByRentCostOnce: (state, action) => {
      state.dataView.searchedByRentCostOnce = action.payload;
    },
    setDataView: (state, action) => {
      const {
        numberOfRooms,
        name,
        map,
        id,
        city_code,
        locationData,
        isDateChange,
        sendToLogin,
      } = action.payload;
      if (sendToLogin) {
        state.dataView = {
          ...state.dataView,
          isVisible: true,
          title: `${name} - ${
            state.datesArray[state.currentDateIndex].dateString
          }`,
          isBeforeLogin: true,
        };
      } else {
        if (!id) {
          if (isDateChange) {
          } else {
            state.selectedCity = CROSS_COUNTRY_SELECT;
            state.citySearch = CROSS_COUNTRY_SELECT.name;
          }
        } else if (city_code) {
          const selectedCity = state.cities.find((city) => city.id === id);
          state.selectedCity = selectedCity;
          state.citySearch = selectedCity.name;
        }
        state.dataView = {
          ...state.dataView,
          isVisible: true,
          locationData,
          isBeforeLogin: false,
          name,
          numberOfRooms: numberOfRooms ?? state.selectedRoomAmount,
          title: `${name} - ${
            state.datesArray[state.currentDateIndex].dateString
          }`,
          mapTitle: name,
          map: map
            ? map === state.dataView.map
              ? map
              : Utils.buildNewsletterLink(`maps/${map}.svg`)
            : MAP_IMAGES.OTHER.PLACEHOLDER,
        };
      }
    },
    setDataViewData: (state, action) => {
      state.dataView.data = action.payload;
      state.oldPercentageValues = {
        monthToMonth: action.payload.monthToMonthDifference?.difference,
        supply: action.payload.supplyDifference?.difference,
      };
    },
    setDataViewRoomNumber: (state, action) => {
      state.dataView.numberOfRooms = action.payload;
    },
    setRoomsToFilter: (state, action) => {
      state.filter.rooms = action.payload;
    },
    handleFilter: (state, action) => {
      state.filter.isOpen = action.payload;
      if (!action.payload) {
        state.filter.infoDisplay = false;
        state.filter.date.isVisible = false;
      }
    },
    initFilterDate: (state, action) => {
      const isAdmin = action.payload;
      state.filter.date = {
        ...state.filter.date,
        from: getCorrectDate(isAdmin).getMonth(),
      };
    },
    handleInfoDisplay: (state) => {
      state.filter.infoDisplay = !state.filter.infoDisplay;
    },
    openDateRangePick: (state) => {
      state.filter.date.isVisible = true;
    },
    handleDateRangeSelect: (state, action) => {
      state.filter.date.isVisible = false;
    },
    selectDateRange: (state, action) => {
      const { from, to } = action.payload;
      state.filter.date.from = from;
      state.filter.date.to = to;
    },
    setStartMonth: (state, action) => {
      state.filter.date.from = action.payload;
    },
    setEndMonth: (state, action) => {
      state.filter.date.to = action.payload;
    },
    setCurrentYearIndex: (state, action) => {
      const index = action.payload;
      state.filter.date.currentYearIndex = index;
      state.filter.date.from = getFromMonth(index);
      state.filter.date.to = null;
    },
    setCities: (state, action) => {
      state.cities = action.payload;
    },
    setStreets: (state, action) => {
      state.streets = action.payload;
    },
    setAreas: (state, action) => {
      state.areas = action.payload;
    },
    setNeighborhoods: (state, action) => {
      state.neighborhoods = action.payload;
    },
    setAssets: (state, action) => {
      const { cityCode, assets } = action.payload;
      state.assets = {
        ...state.assets,
        [cityCode]: assets,
      };
    },
    resetSearchValue: (state, action) => {
      state[action.payload] = "";
    },
  },
});

export const {
  selectRoomNumber,
  selectCity,
  searchCity,
  searchArea,
  selectArea,
  closeDataView,
  openDataView,
  setDatesArray,
  setCurrentDateIndex,
  setMapOptions,
  setDataView,
  setDataViewRoomNumber,
  setRoomsToFilter,
  handleFilter,
  handleInfoDisplay,
  openDateRangePick,
  handleDateRangeSelect,
  selectDateRange,
  setStartMonth,
  setEndMonth,
  setCurrentYearIndex,
  setCities,
  setAreas,
  setNeighborhoods,
  setAssets,
  resetSearchValue,
  setDataViewData,
  setLocationCards,
  setClosestCity,
  selectNeighborhood,
  setIsIntroVisible,
  changeIntroStep,
  setActive,
  setIsChatOpen,
  setMessages,
  setIsWaitingForResponse,
  setLocationCardsData,
  initFilterDate,
  setSelectedProperties,
  setStreets,
  searchStreet,
  selectStreet,
  setDataViewActiveTab,
  setSearchedByRentCostOnce,
} = newsletterSlice.actions;

export const currentDate = (state) =>
  state.newsletter.datesArray[state.newsletter.currentDateIndex];

export default newsletterSlice.reducer;
